import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { SEO } from '../SEO';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

interface Props {
  children: ReactNode;
}

const LayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: 70px 1fr auto;
  min-height: 100vh;
  gap: 1rem;
`;

export const MainLayout = ({ children }: Props) => {
  return (
    <LayoutWrapper>
      <SEO />
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutWrapper>
  );
};
