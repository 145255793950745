import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledNav = styled.nav``;

const NavItems = styled.ul`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin: 0;
  padding: 0;
`;

const NavLink = styled(Link)`
  margin: 0;
  padding: 0;
`;

export const Nav = () => {
  return (
    <StyledNav>
      <NavItems>
        <NavItem>
          <NavLink to='/heroes'>Heros</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to='/about'>About</NavLink>
        </NavItem>
      </NavItems>
    </StyledNav>
  );
};
