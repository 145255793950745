import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

// Hooks
import { useTheme } from '../lib/useTheme';
import { useSystemColor } from '../hooks/useSystemColor';

const ToggleWrapper = styled.button`
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  transition: var(--standard-transition);
  vertical-align: middle;
`;

const ToggleButton = styled(FontAwesomeIcon)`
  color: var(--icon-color);
`;

export const ThemeSelector = () => {
  const { theme, setTheme } = useTheme(); // The current theme
  const prefersDarkMode = useSystemColor(); // @media prefers dark mode
  const [isDark, setIsDark] = useState<boolean | null>(null);

  useEffect(() => {
    if (!theme) {
      setIsDark(() => prefersDarkMode);
    } else {
      const t = theme === 'dark' ? true : false;
      setIsDark(() => t);
    }
  }, [theme, prefersDarkMode]);

  return (
    <div>
      <ToggleWrapper
        role='button'
        aria-label={`Toggle ${isDark ? 'light' : 'dark'} mode`}
        onClick={setTheme}
      >
        {isDark !== null && (
          <ToggleButton focusable icon={isDark ? faMoon : faSun} />
        )}
      </ToggleWrapper>
    </div>
  );
};
