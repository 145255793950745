import React, { createContext, useContext, useState } from 'react';
import { Hero } from '../generated/graphql';

interface Props {
  children: React.ReactNode;
}

interface IHeroContext {
  allHeroes: {
    heroes: {
      node: Hero;
    }[];
    setHeroes: any;
  };
  filteredHeroes: {
    heroes: {
      node: Hero;
    }[];
    setHeroes: any;
  };
  currentHero: {
    hero: Hero | null;
    setHero: any;
  };
}

const HeroContext = createContext<IHeroContext>(null);
const HeroProvider = HeroContext.Provider;

export const LocalHeroProvider = ({ children }: Props) => {
  const [allHeroes, setAllHeroes] = useState<{ node: Hero }[]>([]);
  const [filteredHeroes, setFilteredHeroes] = useState<{ node: Hero }[]>([]);
  const [currentHero, setCurrentHero] = useState<Hero | null>(null);

  const values: IHeroContext = {
    allHeroes: {
      heroes: allHeroes,
      setHeroes: setAllHeroes,
    },
    filteredHeroes: {
      heroes: filteredHeroes,
      setHeroes: setFilteredHeroes,
    },
    currentHero: {
      hero: currentHero,
      setHero: setCurrentHero,
    },
  };

  return <HeroProvider value={values}>{children}</HeroProvider>;
};

export const useHeroes = () => {
  return useContext(HeroContext);
};
