import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  text-align: center;
  padding: 1rem;
  margin-top: 5rem;
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <small>
        <span>
          <Link to={'/'}>Crush Them All Fans</Link> &copy;{' '}
          {new Date().getFullYear()}
        </span>
      </small>
      <div>
        <small>
          <span>Made with ❤️ from 🌎 </span>
          <span> | </span>
          <span>
            Build with{' '}
            <a
              href='http://gatsbyjs.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              GatsbyJS
            </a>{' '}
            and{' '}
            <a
              href='https://www.sanity.io/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Sanity.io
            </a>
          </span>
        </small>
      </div>
      <div>
        <small>
          <span>
            <a
              href='http://buymeacoffee.com/genehenson  '
              target='_blank'
              rel='noopener noreferrer'
            >
              Buy me a 🍺
            </a>
          </span>
        </small>
      </div>
    </FooterWrapper>
  );
};
