export const useLocalStorage = () => {
  const setValue = (key: string, value: string): void => {
    if (window) {
      window.localStorage.setItem(key, value);
    }
  };

  const getValue = (key: string): string => {
    if (window) {
      return window.localStorage.getItem(key);
    }
    return '';
  };

  return [setValue, getValue] as const;
};
