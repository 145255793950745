import React, { useContext, useState } from 'react';

enum EnumFilterTypes {
  'gender',
  'element',
  'rarity',
  'class',
  'hero_type',
}
export type IFilterType = keyof typeof EnumFilterTypes;

export interface IBoolean {
  [key: string]: boolean;
}

export interface ISetFilter {
  [key: string]: () => void;
}

export interface IFilterObject {
  [key: string]: boolean | IFilterType | ISetFilter | IBoolean;
  filterType: IFilterType;
  setFilter?: ISetFilter;
}

interface ILocalState {
  filters: IFilterObject[];
  setFilter: (filterType: IFilterType, value: IBoolean[]) => void;
}

const initialElementFilter: IFilterObject = {
  filterType: 'element',
  fire: true,
  water: true,
  earth: true,
  light: true,
  dark: true,
};

const initialGenderFilter: IFilterObject = {
  filterType: 'gender',
  male: true,
  female: true,
};

const initialTypeFilter: IFilterObject = {
  filterType: 'hero_type',
  ground: true,
  flying: true,
};

const initialRarityFIlter: IFilterObject = {
  filterType: 'rarity',
  common: true,
  rare: true,
  epic: true,
  legendary: true,
};

const initialClassFilter: IFilterObject = {
  filterType: 'class',
  archer: true,
  barbarian: true,
  brawler: true,
  gunner: true,
  knight: true,
  lancer: true,
  magician: true,
  rogue: true,
  samurai: true,
  support: true,
};

// Context
const LocalStateContext = React.createContext<ILocalState>(null);

// Context Provider
const LocalStateProvider = LocalStateContext.Provider;

const ElementStateProvider = ({ children }) => {
  const [selectedElements, setSelectedElements] = useState(
    initialElementFilter
  );
  const [selectedGender, setSelectedGender] = useState(initialGenderFilter);
  const [selectedRarities, setSelectedRarities] = useState(initialRarityFIlter);
  const [selectedClasses, setSelectedClasses] = useState(initialClassFilter);
  const [selectedTypes, setSelectedTypes] = useState(initialTypeFilter);

  const handleChange = (filterType: IFilterType, value: IBoolean[]) => {
    switch (filterType) {
      case 'element':
        const ele: IFilterObject = Object.assign(
          { ...selectedElements },
          ...value
        );
        setSelectedElements(() => ele);
        break;
      case 'gender':
        const gen: IFilterObject = Object.assign(
          { ...selectedGender },
          ...value
        );
        setSelectedGender(() => gen);
        break;
      case 'rarity':
        const rare: IFilterObject = Object.assign(
          { ...selectedRarities },
          ...value
        );
        setSelectedRarities(() => rare);
        break;
      case 'class':
        const cla: IFilterObject = Object.assign(
          { ...selectedClasses },
          ...value
        );
        setSelectedClasses(() => cla);
        break;
      case 'hero_type':
        const ht: IFilterObject = Object.assign({ ...selectedTypes }, ...value);
        setSelectedTypes(() => ht);
        break;

      default:
        console.log('oops');
        break;
    }
  };

  return (
    <LocalStateProvider
      value={{
        filters: [
          selectedElements,
          selectedGender,
          selectedRarities,
          selectedClasses,
          selectedTypes,
        ],
        setFilter: handleChange,
      }}
    >
      {children}
    </LocalStateProvider>
  );
};

// Hooks
const useElementState = () => {
  const all = useContext(LocalStateContext);
  return all;
};

export { ElementStateProvider, useElementState };
