import React from "react";
import { MainLayout } from "./src/components/Layouts/MainLayout";
import { ElementStateProvider } from "./src/lib/state";
import { LocalThemeProvider } from "./src/lib/useTheme";
import { LocalHeroProvider } from "./src/lib/heroContext";

import './src/styles/global.css'

export const wrapPageElement = ({ element, props }) => {
  return (
    <LocalHeroProvider>
      <LocalThemeProvider>
        <ElementStateProvider><MainLayout {...props}>{element}</MainLayout></ElementStateProvider>
      </LocalThemeProvider>
    </LocalHeroProvider>
  )
}