import React from 'react';
import styled from 'styled-components';
import { Nav } from './Nav';
import { Logo } from './Logo';
import { ThemeSelector } from './DarkModeToggle';

const StyledHeader = styled.div`
  border-bottom: 1px solid var(--surface-border);
  transition: var(--standard-transition);
`;

const HeaderWrapper = styled.header`
  display: grid;
  grid-template-columns: auto 1fr 1rem;
  gap: 1rem;

  align-content: center;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  max-width: var(--wide-width);
  margin: 0 auto;
  padding: 0 1rem;
  transition: var(--standard-transition);
`;

const NavWrapper = styled.div`
  justify-self: end;
`;

export const Header = () => {
  return (
    <StyledHeader>
      <HeaderWrapper>
        <Logo />
        <NavWrapper>
          <Nav />
        </NavWrapper>
        <ThemeSelector />
      </HeaderWrapper>
    </StyledHeader>
  );
};
