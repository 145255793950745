import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useSystemColor } from '../hooks/useSystemColor';

enum ThemeEnums {
  light = 'light',
  dark = 'dark',
}

type ThemeTypes = ThemeEnums.dark | ThemeEnums.light;

interface Props {
  children: React.ReactNode;
}

interface IThemeContext {
  theme?: keyof typeof ThemeEnums;
  setTheme?: () => void;
}

const ThemeContext = createContext<IThemeContext>(null);
const ThemeProvider = ThemeContext.Provider;

export const LocalThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState<ThemeTypes>();
  const prefersDarkMode = useSystemColor();
  const [setValue, getValue] = useLocalStorage();

  useEffect(() => {
    const localTheme = getValue('theme');
    if (localTheme && localTheme in ThemeEnums) {
      // We have to ignore this line becuase the
      // TS compiler doesn't know that we already
      // checked the type of localTheme
      // @ts-ignore
      setTheme(() => localTheme);
    }
  }, []);

  function toggleTheme() {
    if (theme === 'light') {
      setTheme(() => ThemeEnums.dark);
      setValue('theme', ThemeEnums.dark);
    } else if (theme === 'dark') {
      setTheme(() => ThemeEnums.light);
      setValue('theme', ThemeEnums.light);
    } else if (prefersDarkMode) {
      setTheme(() => ThemeEnums.light);
      setValue('theme', ThemeEnums.light);
    } else {
      setTheme(() => ThemeEnums.dark);
      setValue('theme', ThemeEnums.dark);
    }
  }

  return (
    <ThemeProvider value={{ theme: theme, setTheme: toggleTheme }}>
      {children}
    </ThemeProvider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
