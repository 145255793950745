// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layouts-hero-template-tsx": () => import("./../../../src/components/Layouts/HeroTemplate.tsx" /* webpackChunkName: "component---src-components-layouts-hero-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-game-modes-tsx": () => import("./../../../src/pages/gameModes.tsx" /* webpackChunkName: "component---src-pages-game-modes-tsx" */),
  "component---src-pages-heroes-tsx": () => import("./../../../src/pages/heroes.tsx" /* webpackChunkName: "component---src-pages-heroes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

